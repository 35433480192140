/**
 * @generated SignedSource<<ba831bf30e8d827e3fea204cc35271fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type useFaqs_faqs$data = {
  readonly ' $fragmentSpreads': FragmentRefs<
    | 'useFaqs_tour'
    | 'useFaqs_price'
    | 'useFaqs_cemeteryHas'
    | 'useFaqs_successor'
  >
  readonly ' $fragmentType': 'useFaqs_faqs'
}
export type useFaqs_faqs$key = {
  readonly ' $data'?: useFaqs_faqs$data
  readonly ' $fragmentSpreads': FragmentRefs<'useFaqs_faqs'>
}

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'useFaqs_faqs',
  selections: [
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'useFaqs_tour'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'useFaqs_price'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'useFaqs_cemeteryHas'
    },
    {
      args: null,
      kind: 'FragmentSpread',
      name: 'useFaqs_successor'
    }
  ],
  type: 'Cemetery',
  abstractKey: null
}

;(node as any).hash = '80523db4b91c986f79fb6c8fa2c71614'

export default node
